import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'OBO-BUSD LP',
    lpAddresses: {
      97: '0x578ac714d6f7c9920772696d7d502761f741032b',
      56: '0x24dE45a797473Fb992963fF4C7783bdEEf906b5a',
    },
    tokenSymbol: 'OBO',
    tokenAddresses: {
      97: '0x35ED0d2CC076E2c4b7843832cbFaB8A0378E74f9',
      56: '0x8F3a13AeA609A34C34E3f598ed594E1e2bEE3E5c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  
]

export default farms
