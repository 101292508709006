import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { ChainId } from '@oboswap/bsc-sdk'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { useMulticallContract } from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import priceContracts from '../constants/eggPriceContracts'
import farmsConfig from '../constants/farms'


type ApiResponse = {
  prices: {
    [key: string]: string
  }
  update_at: string
}

// /**
//  * Due to Cors the api was forked and a proxy was created
//  * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
//  */
const api = 'https://api.pancakeswap.com/api/v1/price'

const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)
  
  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){
          const {cakeAddress, busdAddress, lpAddress} = priceContracts;
          // const calls = [
          //   [cakeAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          //   [busdAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          // ];

          // const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          // const [cakeAmount, busdAmount] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          // const cake = new BigNumber(cakeAmount);
          // const busd = new BigNumber(busdAmount);
          // const cakePrice = busd.div(cake).toNumber();
          // // console.log(busd.toString());
          // setData(cakePrice)


          farmsConfig.map(async (farmConfig) => {
            const lpAdress = lpAddress
   
            const calls2 = [
              // Balance of token in the LP contract
              {
                address: cakeAddress,
                name: 'balanceOf',
                params: [lpAdress],
              },
              // Balance of quote token on LP contract
              {
                address: farmConfig.quoteTokenAdresses[ChainId.MAINNET],
                name: 'balanceOf',
                params: [lpAdress],
              },
              // Balance of LP tokens in the master chef contract
              {
                address: farmConfig.isTokenOnly ? cakeAddress : lpAdress,
                name: 'balanceOf',
                params: [getMasterChefAddress()],
              },
              // Total supply of LP tokens
              {
                address: lpAdress,
                name: 'totalSupply',
              },
              // Token decimals
              {
                address: cakeAddress,
                name: 'decimals',
              },
              // Quote token decimals
              {
                address: busdAddress,
                name: 'decimals',
              },
            ]
      
            // const [
            //   tokenBalanceLP,
            //   quoteTokenBlanceLP,
            //   lpTokenBalanceMC,
            //   lpTotalSupply,
            //   tokenDecimals,
            //   quoteTokenDecimals,
            // ] = await multicall(erc20, calls)

            // const [resultsBlockNumber, result] = await multicallContract.aggregate(calls1);
   

           const calls1 = [
        [cakeAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
        [farmConfig.quoteTokenAdresses[ChainId.MAINNET], ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
        [lpAdress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [getMasterChefAddress()])],
        [lpAdress, ERC20_INTERFACE.encodeFunctionData("totalSupply")],
        [cakeAddress, ERC20_INTERFACE.encodeFunctionData("decimals")],
        [busdAddress, ERC20_INTERFACE.encodeFunctionData("decimals")],
      ];

      const [resultsBlockNumber1, result1] = await multicallContract.aggregate(calls1);
        const [
              tokenBalanceLP,
              quoteTokenBlanceLP,
              lpTokenBalanceMC,
              lpTotalSupply,
              tokenDecimals,
              quoteTokenDecimals,
            ] = result1.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
            //  console.log(tokenBalanceLP.toString())
            //  console.log(quoteTokenBlanceLP.toString())
            //  console.log(lpTokenBalanceMC.toString())
            //  console.log(lpTotalSupply.toString())
            //  console.log(tokenDecimals.toString())
            //  console.log(quoteTokenDecimals.toString())

            let tokenPriceVsQuote
         
            //   // Ratio in % a LP tokens that are in staking, vs the total number in circulation
              const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
      
            //   // Total value in staking in quote token value
             const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
                .div(new BigNumber(10).pow(18))
                .times(new BigNumber(2))
                .times(lpTokenRatio)
      
            // //     // console.log(lpTotalInQuoteToken.toString())
      
            //   // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
             const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      
            //   //  console.log(tokenAmount.toString())
              const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
                .div(new BigNumber(10).pow(quoteTokenDecimals))
                .times(lpTokenRatio)
                
  
            //         // console.log(farmConfig.tokenAddresses[137])
            //     // console.log(tokenAmount.toString())
               
              if (tokenAmount.comparedTo(0) > 0) {
                // console.log(quoteTokenAmount.toString())
                // console.log(tokenAmount.toString())
                tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount).toNumber()
               
              } else {
                
                tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP)).toNumber()
              }
         
      
            
            setData(tokenPriceVsQuote)
            // return true;
          })
    
        } 
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}




export default useGetPriceData
