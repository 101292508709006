export default {
  cake: {
    56: '0x8F3a13AeA609A34C34E3f598ed594E1e2bEE3E5c',
    97: '0x35ED0d2CC076E2c4b7843832cbFaB8A0378E74f9',
  },
  masterChef: {
    56: '0x6c716af369343bB723415d95e33c406e6A7D61Fb',
    97: '0x0f0CBe295bFfE3103b13D69BE8814Fe675D24d7c',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x3A43c0bCcb4edc19FEa8E83c80cfc688Da93E7C7',
    97: '0xC9CDf4D3D360F8256a12E8fd31f1Cc6FC28d652C',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
  },
}
